import { render, staticRenderFns } from "./FormsPanel.vue?vue&type=template&id=d610d474&scoped=true"
import script from "./FormsPanel.vue?vue&type=script&lang=js"
export * from "./FormsPanel.vue?vue&type=script&lang=js"
import style0 from "./FormsPanel.vue?vue&type=style&index=0&id=d610d474&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d610d474",
  null
  
)

export default component.exports